<template>
  <div style="" class="slip-list p-2 my-2">
    <section class="d-flex justify-content-left game-card">
     
      <div class="content-section">
        
        <div class="row m-0 mt-1">
          <div class="col-9 m-0 p-0">
            <div style="font-weight: bold;" class="teams text-light">
              {{ bet.competitor_1 }}  v {{ bet.competitor_2 }}  <span v-if="bet.live" class="text-danger">Live</span>
            </div>
          </div>
         
          <div style="font-weight: bold;" class="col-3 m-0 p-0 text-right odds-tag">
            <span class="odds-bts text-light">{{ bet.odds | formatOdds }}</span>
          </div>
        </div>
        
        <div class="row m-0 p-0">

          <div class="col-4 m-0 p-0">
            <div style="font-weight: bold;" class="small-text text-light op-low">
              {{ bet.market_name }}
            </div>
          </div>
          
          <div class="col-8 m-0 p-0 pick text-light small-text">
            <div style="font-weight: bold;" class="pick text-light small-text p-0 text-right op-low">
              Pick: <span class="text-light">{{ bet.outcome_name }}</span>
            </div>
          </div>

        </div>
      </div>
      <a class="custom-link">
        <div class="py-2 px-3 text-light remove-match-betslip" @click="removePick(bet.match_id)">
          x
        </div>
      </a>
    </section>
  </div>

</template>

<script>

export default {
  name: "Pick",
  props: {
    bet: {
      required: true
    }
  },
  methods: {
    init: function(){

      var vm = this;

      vm.previousOdds = this.bet.odds;

      vm.id = this.getOddID(this.bet.match_id,this.bet.market_id,this.bet.outcome_id,this.bet.specifier,'odd');
      this.EventBus.$on('odds:'+vm.id,function(payload){

        var hasChanged = false;

        if(parseFloat(payload.odds) !== parseFloat(vm.bet.odds)) {

          hasChanged = true;
        }

        if(parseInt(vm.bet.active) !== parseInt(payload.active)) {

          hasChanged = true;
        }

        if(parseInt(vm.bet.status) !== parseInt(payload.status)) {

          hasChanged = true;
        }

        if(hasChanged) {

          console.log(JSON.stringify(payload,undefined, 4));

          vm.bet.status = payload.status;
          vm.bet.active = payload.active;

          var oddsChangedPayload = {};

          oddsChangedPayload.id = vm.id;
          oddsChangedPayload.odds = payload.odds;
          oddsChangedPayload.status = payload.status;
          oddsChangedPayload.active = payload.active;
          oddsChangedPayload.previous_odds = payload.odds;

          console.log('current '+payload.odds+' previous '+vm.bet.odds);

          if (parseFloat(payload.odd) !== parseFloat(vm.bet.odds)) {

            vm.previousOdds = vm.bet.odds;
            vm.bet.odds = payload.odds;

            // odds changed
            if (parseFloat(payload.odds) > parseFloat(vm.previousOdds)) {

              var direction = 1

            } else {

              direction = 0

            }

            console.log('direction '+direction);

            var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
            directionTxt = directionTxt + ' from ' + vm.formatOdds(vm.previousOdds) + ' to ' + vm.bet.odd;
            vm.bet.odds_change_text = directionTxt;
            oddsChangedPayload.previous_odds = vm.previousOdds;
            console.log('directionTxt '+directionTxt);

          }

          vm.EventBus.$emit('odds:changed', oddsChangedPayload);

        }

      });

      this.EventBus.$on('producer:status',function(payload){

        //var producer_id = payload.producer_id;
        vm.bet.producer_status = payload.producer_status;

        //if(parseInt(producer_id) === 1 ) {

        //vm.bet.producer_status = producer_status;

        // }

      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  mounted: function () {

    this.init();

  },
  data: function () {

    return {
      id: '',
      statusClass: '',
      previousOdds: '',
      oddActive:'',
      producerStatus: '',
      locked: false,
    }
  },
  watch: {
    bet: function (){
      this.init();
    }
  },
  computed: {

    directionText: function (){

      return this.odds_change_text;

    }

  }
}
</script>

<style scoped>

.odds-bts {
  background: var(--light-gray);
  padding: 0.1rem 0.2rem;
  border-radius: 9999px;
  display: inline-block;
}
.content-section { 
  flex-grow: 1;
}
</style>